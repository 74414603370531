import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MockClpLoaderService, HttpModule as SecoHttpModule } from '@seco/dev-utils';
import { ClpLoaderService, ClpService, LoginModule } from '@seco/login';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { Bear1AXMLRSFService, HttpConfigService, ReefDatePipe, ReefServerModule } from '@seco/core';
import {
  AirpBookServiceMock,
  AirpPnrServiceMock,
  AirpShopServiceMock,
  BookService,
  ConfigurationService,
  CustomerConfigurationService,
  GlobalListService,
  GlobalListServiceMock,
  InsuranceModule,
  PnrService,
  ShopService,
  InputFieldService
} from '@seco/insurance';
import { Bear1AXMLService } from 'bear-1axml';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/login/login.component';
import { PipesModule } from './core/util/pipes/pipes.module';
import { KeepAliveService } from './service/keep-alive/keep-alive.service';
import { LoginService } from './service/login.service';
import { ParameterGuardService } from './service/parameter-guard/parameter-guard.service';
import { Effects } from './store/core/effects';
import { coreReducer } from './store/core/reducer';
import { CORE_FEATURE } from './store/core/state';
import { metaReducers, reducers } from './store/root.reducers';
import { AirpCustomerConfigurationService } from './service/customer-configuration/airp-customer-configuration.service';
import { AirpConfigurationServiceMock } from './service/customer-configuration/airp-configuration.service.mock';

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    SecoHttpModule.forRoot(undefined, environment.useMockServer),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature(CORE_FEATURE, coreReducer),
    EffectsModule.forRoot([Effects]),
    LoginModule,
    ReactiveFormsModule,
    ReefServerModule.forRoot(),
    AppRoutingModule,
    PipesModule,
    InsuranceModule
  ],
  providers: [
    LoginService,
    ClpService,
    ParameterGuardService,
    InputFieldService,
    ReefDatePipe,
    KeepAliveService,
    { provide: ClpLoaderService, useClass: environment.useMockServer ? MockClpLoaderService : ClpLoaderService },
    // No global list as we are out Of JCP
    { provide: GlobalListService, useClass: GlobalListServiceMock },
    // Our of JCP , no DB so not able to retrieve config from DB for now
    { provide: ConfigurationService, useClass: AirpConfigurationServiceMock },
    // Static Customer configuration
    { provide: CustomerConfigurationService, useClass: AirpCustomerConfigurationService},
    { provide: Bear1AXMLService, useClass: Bear1AXMLRSFService },
    // To mock collateral call services
    { provide: BookService, useClass: environment.useMockServer ? AirpBookServiceMock : BookService },
    { provide: ShopService, useClass: environment.useMockServer ? AirpShopServiceMock : ShopService },
    { provide: PnrService, useClass: environment.useMockServer ? AirpPnrServiceMock : PnrService }
  ],
  bootstrap: [AppComponent],
  exports: [LoginComponent]
})
export class AppModule {
  constructor(private readonly httpConfigService: HttpConfigService) {
    this.httpConfigService.initConfig({
      siteCode: 'AIRPAIRP',
      language: 'GB',
      baseUrl: '/app_airp_ui'
    });
  }
}
