import {
  INPUT_BIRTHDATE_INFORMATION,
  INPUT_BIRTHDATE_SEARCHING,
  INPUT_DOCUMENT_NUMBER_INFORMATION,
  INPUT_DOCUMENT_TYPE_INFORMATION,
  PassengerTypeEnum,
  ConfigurationForAllCustomersAndMarkets
} from '@seco/insurance';

export const DEFAULT_MARKET = 'DEFAULT';

export const AIRP_CUSTOMER_CONFIG_DUMMY: { [customerId: string]: ConfigurationForAllCustomersAndMarkets } = {
  //For testing purposes
  '6X': {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'INB',
            name: 'Test Provider',
            inputConfigs: [
              {
                inputField: INPUT_BIRTHDATE_SEARCHING,
                passengerType: PassengerTypeEnum.POLICYHOLDER,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_INFORMATION,
                passengerType: PassengerTypeEnum.POLICYHOLDER,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
                passengerType: PassengerTypeEnum.POLICYHOLDER,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
                passengerType: PassengerTypeEnum.POLICYHOLDER,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_SEARCHING,
                passengerType: PassengerTypeEnum.ADT,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_INFORMATION,
                passengerType: PassengerTypeEnum.ADT,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
                passengerType: PassengerTypeEnum.ADT,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
                passengerType: PassengerTypeEnum.ADT,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_SEARCHING,
                passengerType: PassengerTypeEnum.CHD,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_INFORMATION,
                passengerType: PassengerTypeEnum.CHD,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
                passengerType: PassengerTypeEnum.CHD,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
                passengerType: PassengerTypeEnum.CHD,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_SEARCHING,
                passengerType: PassengerTypeEnum.INF,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_BIRTHDATE_INFORMATION,
                passengerType: PassengerTypeEnum.INF,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_TYPE_INFORMATION,
                passengerType: PassengerTypeEnum.INF,
                mandatory: true,
                editable: false,
                enabled: true
              },
              {
                inputField: INPUT_DOCUMENT_NUMBER_INFORMATION,
                passengerType: PassengerTypeEnum.INF,
                mandatory: true,
                editable: false,
                enabled: true
              }
            ]
          }
        ]
      }
    ]
  }
};
