import { Injectable } from '@angular/core';
import { AIRP_CUSTOMER_CONFIG } from '../../configuration/customer-configuration/customer-configuration';
import { AIRP_CUSTOMER_CONFIG_DUMMY } from '../../configuration/customer-configuration/customer-configuration.tst';
import { CustomerConfigurationService } from '@seco/insurance';

@Injectable()
export class AirpCustomerConfigurationService extends CustomerConfigurationService {
  constructor() {
    super();

    this.environments = {
      pdt: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      uat: { ...AIRP_CUSTOMER_CONFIG, ...AIRP_CUSTOMER_CONFIG_DUMMY },
      prd: AIRP_CUSTOMER_CONFIG
    };
  }
}
