import { Injectable } from '@angular/core';
import { AIRP_CONFIGURATION, Configuration, ConfigurationService } from '@seco/insurance';
import { Observable, of } from 'rxjs';

/**
 * For now AIRP is not linked to any DB configuration
 * This mock to ensure that the template is always set to the good one
 */
@Injectable()
export class AirpConfigurationServiceMock extends ConfigurationService {
  constructor() {
    super(null);
  }

  getConfig(): Observable<Configuration> {
    return of(AIRP_CONFIGURATION);
  }
}
