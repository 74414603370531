import { ConfigurationForAllCustomersAndMarkets } from '@seco/insurance';
import { MOCKED_CUSTOMER_INPUT_CONFIG } from './customer-input-group';

export const DEFAULT_MARKET = 'DEFAULT';

export const AIRP_CUSTOMER_CONFIG: { [customerId: string]: ConfigurationForAllCustomersAndMarkets } = {
  //Air Caraibes
  TX: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'TXA',
            name: 'Assurever',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  //Philippine Airlines
  PR: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'PAP',
            name: 'CHUBB',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ALL_FIELDS_EXCEPT_COUNTRY_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_BIRTHDATE_FOR_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_BIRTHDATE_FOR_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_BIRTHDATE_FOR_INFANT']
            ]
          }
        ]
      }
    ]
  },
  //CORSAIR
  SS: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'PRE',
            name: 'Presence',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  //Air Tahiti Nui Airlines
  TN: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'ATN',
            name: 'Allianz',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_AND_PHONE_NUMBER_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  //Air Calin
  AC: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'EAC',
            name: 'Europ Assistance',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // RwandAir
  WB: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'RWA',
            name: 'Radiant Insurance',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // Air India
  AI: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'AIN',
            name: 'TATA AIG',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_INDIA_CONFIG_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_INDIA_CONFIG_FOR_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_INDIA_CONFIG_FOR_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_INDIA_CONFIG_FOR_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // Aegean Airlines
  A3: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'AEE',
            name: 'Allianz',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // Olympic Air
  OA: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'AEE',
            name: 'Allianz',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // Etihad Airways
  EY: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'ETH',
            name: 'AXA',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // Bamboo Airways
  QH: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'BAV',
            name: 'CHUBB',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ALL_FIELDS_EXCEPT_COUNTRY_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_BIRTHDATE_FOR_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_BIRTHDATE_FOR_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_BIRTHDATE_FOR_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // Vietnam Airlines
  VN: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'VNA',
            name: 'Chubb',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['VIETNAM_CONFIG_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['VIETNAM_CONFIG_FOR_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['VIETNAM_CONFIG_FOR_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['VIETNAM_CONFIG_FOR_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // French Bee
  BF: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'FRB',
            name: 'Allianz',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
  // ITA Airways
  AZ: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'ITA',
            name: 'Chubb',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['ONLY_EMAIL_AND_PHONE_NUMBER_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['NO_SPECIAL_FIELDS_INFANT']
            ]
          }
        ]
      }
    ]
  },
   // AIR AUSTRAL Airlines
   UU: {
    marketConfiguration: [
      {
        market: DEFAULT_MARKET,
        providers: [
          {
            code: 'UUA',
            name: 'Assurever',
            inputConfigs: [
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_AUSTRAL_CONFIG_FOR_POLICYHOLDER'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_AUSTRAL_CONFIG_FOR_ADULT'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_AUSTRAL_CONFIG_FOR_CHILD'],
              ...MOCKED_CUSTOMER_INPUT_CONFIG['AIR_AUSTRAL_CONFIG_FOR_INFANT']
            ]
          }
        ]
      }
    ]
  }
};
